/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setCartQuantity } from "../../../features/cartQuantity/cartQuantitySlice";

import axios from "../../../api/axios";
import {
  PRODUCT_LIST_CONFIG_URL,
  CART_DETAILS_CONFIG_URL,
} from "../../../api/api_routing_urls";

import "./Home.css";

import { BsArrowDown } from "react-icons/bs";

import ProductCard from "../../../reusable-components/product-card/productCard.component";

import newComingSoonMobile from "../../../assets/mobileBannerApril.jpg";
import newComingSoonDesktop from "../../../assets/desktopBannerApril.jpg";
import followingBanner from "../../../assets/followingBannerUpdated.jpg";
import writeupImg from "../../../assets/WriteupUpdated.jpg";

import CookieModal from "../../../reusable-components/modals/CookieModal";
import Newsletter from "./newsletter.component";

import AnimatedLoader from "../../../reusable-components/loader/animated_loader.component";
import { generateQueryString } from "../../../payment/generateQueryString";

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector((state) => state.authProvider.auth);

  const user_id = auth?.user_id;
  const cartQuantity = useSelector((state) => state.cartQuantity.cartQuantity);

  const [isCookieModalOpen, setIsCookieModalOpen] = useState(true);

  const bannerHomePage = useRef();

  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [firstDivHeight, setFirstDivHeight] = useState(0);
  const [isBannerDiv, setIsBannerDiv] = useState(true);

  const [showProductDetailsPage, setShowProductDetailsPage] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getProductListDetails = async () => {
    try {
      const response = await axios.get(PRODUCT_LIST_CONFIG_URL);
      // console.log({ response });
      if (response.status === 200) {
        setProductList(
          response?.data?.productCompleteDetails
            ?.get_products_complete_details_v2
        );

        setIsLoading(false);
      }
      if (response.status === 202) {
        console.log("Product List details not found in the system.", "error");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.", "error");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator.",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  // console.log("productList", productList);

  useEffect(() => {
    scrollToTop();

    let isMounted = true;
    setShowProductDetailsPage(false);
    setSelectedProduct(null);
    if (isMounted) {
      if (location.pathname === "/") {
        // window.location.reload();
      }
      getProductListDetails();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getCartItemsQuantityDetails = async (user_id) => {
    try {
      const response = await axios.get(
        `${CART_DETAILS_CONFIG_URL}/getTotalItems`,
        {
          params: {
            user_id,
          },
        }
      );
      // console.log("Total items in cart", { response });
      if (response.status === 200) {
        dispatch(setCartQuantity(+response?.data?.cartItemsQuantityDetails));
      } else if (response.status === 202) {
        console.log("No details found in the system.");
      }
    } catch (error) {
      console.error("getCartItemsQuantityDetails", error);
      if (!error?.response) {
        console.log("No Server Response");
      } else if (error.response.status === 422) {
        console.log("Some of the required inputs were not provided.");
      } else {
        console.log(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator."
        );
      }
    }
  };

  //useEffect to fetch the total items in cart of a logged-in user
  useEffect(() => {
    let isMounted = true;

    if (isMounted && user_id) {
      getCartItemsQuantityDetails(user_id);
    }

    return () => {
      isMounted = false;
    };
  }, [user_id]);

  const onShopClickHandler = () => {
    let elem = document.getElementById("shopdiv");
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (localStorage.getItem("CookieModal") == "true") {
      setIsCookieModalOpen(false);
    }
  }, []);

  // Section to handle text color of glassy filter in mobile phones

  useEffect(() => {
    // console.log("bannerHomePage", bannerHomePage);
    if (bannerHomePage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerHomePage?.current?.offsetHeight);
    }
  }, [bannerHomePage?.current?.offsetHeight]);

  useEffect(() => {
    const updateFilterTextColor = () => {
      if (window.scrollY >= firstDivHeight / 5) {
        setIsBannerDiv(false);
      } else {
        setIsBannerDiv(true);
      }
    };

    window.addEventListener("scroll", updateFilterTextColor);

    return () => window.removeEventListener("scroll", updateFilterTextColor);
  }, [firstDivHeight]);

  return (
    <>
      {isLoading ? (
        <div className="bg-white h-screen flex items-center justify-center">
          <AnimatedLoader />
        </div>
      ) : (
        <section className="noSelect font-tenorsans">
          {isCookieModalOpen && (
            <>
              <CookieModal
                title={"your cookie settings"}
                message={`By clicking "Accept All Cookies", you agree to the
         storing of cookies on your device to enhance site
         navigation, analyze site usage, and assist in our 
         marketing efforts. `}
                setIsCookieModalOpen={setIsCookieModalOpen}
              />
            </>
          )}

          <section className="relative">
            <div>
              {/* Desktop Banner */}
              {/* <div className="hidden lg:block">
                <div className="relative">
                  <img
                    src={horizontalBgImage}
                    className="object-cover w-full"
                  />

                  <div
                    onClick={() => onShopClickHandler()}
                    className="cursor-pointer absolute bottom-[35%] md:bottom-[10%] left-[50%] transform -translate-x-1/2 font-light text-xs text-white"
                  >
                    <span className="uppercase underline underline-offset-4 text-[10px]">
                      Explore
                    </span>
                  </div>
                </div>
              </div> */}

              {/* Mobile and Tablet Banner */}
              {/* <div className="relative block lg:hidden font-tenorsans">
                <div className="block md:hidden">
                  <div className="">
                    <img
                      src={verticalBgImage}
                      className="object-cover min-h-screen"
                    />
                  </div>
                </div>

                <div className="hidden md:block lg:hidden">
                  <div className="">
                    <img src={horizontalBgImage} className="object-fill" />
                  </div>
                </div>

                <div
                  onClick={() => onShopClickHandler()}
                  className="cursor-pointer absolute bottom-[30%] md:bottom-[10%] left-[50%] transform -translate-x-1/2 font-light text-xs text-white"
                >
                  <span className="uppercase underline underline-offset-4 text-[10px]">
                    Explore
                  </span>
                </div>
              </div> */}

              {/* Cloth Filter section for desktop, tablets start*/}
              {/* <div className="hidden md:block pb-5 bg-white">
                  <div className="w-[60%] mx-auto">
                    <ul className="items-center w-full text-sm font-medium text-gray-900 flex py-2">
                      <li className="cursor-pointer w-full">
                        <Link to={`/products/${"exclusive"}`}>
                          <div className="flex items-center pl-3">
                            <input
                              id="radio-for-all"
                              type="radio"
                              value=""
                              name="list-radio"
                              className="cursor-pointer w-2.5 h-2.5 text-black focus:outline-none focus:border-0 focus:ring-0"
                            />
                            <label
                              for="radio-for-all"
                              className="cursor-pointer w-full  ml-2 text-xs font-medium text-gray-900 uppercase lg:text-sm"
                            >
                              Exclusive
                            </label>
                          </div>
                        </Link>
                      </li>

                      <li className="cursor-pointer w-full">
                        <Link to={`/products/${"blank"}`}>
                          <div className="flex items-center pl-3">
                            <input
                              id="radio-for-sweatshirts"
                              type="radio"
                              value=""
                              name="list-radio"
                              className="cursor-pointer w-2.5 h-2.5 text-black focus:outline-none focus:border-0 focus:ring-0"
                            />
                            <label
                              for="radio-for-sweatshirts"
                              className="cursor-pointer w-full ml-2 text-xs font-medium text-gray-900 uppercase lg:text-sm"
                            >
                              Blank
                            </label>
                          </div>
                        </Link>
                      </li>

                      <li className="w-full dark:border-gray-600">
                        <Link to={`/products/${"signature edition"}`}>
                          <div className="flex items-center pl-3">
                            <input
                              id="radio-for-hoodies"
                              type="radio"
                              value=""
                              name="list-radio"
                              className="cursor-pointer w-2.5 h-2.5 text-black focus:outline-none focus:border-0 focus:ring-0"
                            />
                            <label
                              for="radio-for-hoodies"
                              className="cursor-pointer w-full ml-2 text-xs font-medium text-gray-900 uppercase lg:text-sm"
                            >
                              Signature Edition
                            </label>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              {/* Cloth Filter section for desktop end*/}

              <div id="shopdiv">
                {productList?.length > 100 ? (
                  <div className="px-3 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {productList?.map((productObj) => (
                      <div
                        key={productObj?.product_id}
                        // onClick={() => onProductClickHandler(productObj)}
                      >
                        <Link
                          to={`/view-product-details/${productObj?.product_id}`}
                        >
                          <ProductCard
                            productObj={productObj}
                            productid={productObj?.product_id}
                            productthumbnail={
                              productObj?.product_images[0]?.file_url
                            }
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {/* <div className="relative">
                      <img
                        src={comingSoonBgImage}
                        className="h-[100vh] w-[100vw] object-cover"
                      />

                      <div className="absolute top-[20%] md:left-[50%] md:-translate-x-1/2">
                        <div className="flex justify-center text-2xl md:text-3xl lg:text-5xl font-semibold uppercase tracking-widest">
                          Coming Soon!
                        </div>

                        <div className="my-5 w-[1%] mx-auto h-20 border-l border-gray-500"></div>

                        <div className="text-center text-sm">
                          <div>
                            We are currently working on creating something
                            fantastic.
                          </div>
                          <div>We will be here soon.</div>

                          <div className="mt-8 lg:mt-5">
                            Until then, you can get in touch with us at{" "}
                            <strong>support@gratitudefg.com</strong>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="block md:hidden">
                      <img
                        src={newComingSoonMobile}
                        className="h-[100vh] object-cover w-full relative"
                      />
                      {/* <div className="absolute top-[18%] left-[50%] -translate-x-1/2 text-black text-[8px] font-normal uppercase font-roboto-condensed">
                        Rolling out this April - stay tuned!
                      </div> */}
                      <img src={followingBanner} className="w-full mt-3" />
                    </div>

                    <div className="hidden md:block lg:hidden">
                      <div className="relative">
                        <img
                          src={newComingSoonDesktop}
                          className="h-[100vh] object-cover w-full "
                        />
                        <div className="absolute top-[58%] left-[50%] -translate-x-1/2 text-[#ffeb3c] text-4xl font-extrabold uppercase font-roboto-condensed">
                          Available Mid-April 2025
                        </div>
                      </div>

                      <img src={followingBanner} className="w-full mt-3" />
                    </div>

                    <div className="hidden lg:block">
                      <div className="relative">
                        <img src={newComingSoonDesktop} className="" />

                        <div className="absolute top-[60%] left-1/2 -translate-x-1/2 text-[#ffeb3c] text-[50px] font-bold uppercase font-roboto-condensed">
                          Available Mid-April 2025
                        </div>
                      </div>

                      <img src={followingBanner} className="w-full mt-3" />
                    </div>

                    <div>
                      <img src={writeupImg} className="w-full mt-3" />
                    </div>
                  </div>
                )}
              </div>

              {/* <>
                  <Newsletter />
                </> */}

              {/* <div className="block md:hidden bg-white px-4 pt-6 pb-16 text-[11px]">
                  <p className="text-left uppercase">
                    <Link to="/about-gratitude">About</Link>
                  </p>
                  <p className="text-left uppercase">
                    <Link to="/legal-notice">Legal Notice</Link>
                  </p>
                  <p className="text-left uppercase">
                    <Link to="/return-refund-policy">
                      Return And Refund Policy
                    </Link>
                  </p>
                  <p className="text-left uppercase">
                    <Link to="/shipping-policy">Shipping Policy</Link>
                  </p>
                </div> */}
            </div>

            {/* Mirror effect Cloth Filter Section for mobile phones */}
            {/* <nav
                className={`md:hidden fixed z-30 bottom-0 py-4 bg-[#F7F7F7] bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-40 w-full ${
                  isBannerDiv ? "text-white" : "text-black"
                }`}
              > */}

            {/* <nav
                className={`md:hidden fixed z-30 bottom-0 py-4 bg-[#7b7b7b] w-full text-black`}
              >
                <div className="grid grid-cols-3 uppercase text-center">
                  <Link to={`/products/${"exclusive"}`}>
                    <div className="text-[11px]">Exclusive</div>
                  </Link>

                  <Link to={`/products/${"blank"}`}>
                    <div className="text-xs">Blank</div>
                  </Link>

                  <Link to={`/products/${"signature edition"}`}>
                    <div className="text-[11px]">Signature</div>
                  </Link>
                </div>
              </nav> */}
          </section>
        </section>
      )}
    </>
  );
};
export default Home;
